import React, { useEffect, useRef } from 'react';
import { CrateReward } from '../../Networking/Interface/Gamba/GambaInterface';
import styles from './CrateAnimation.module.css';


interface CrateAnimationProps {
    possibleRewards: CrateReward[];
    wonReward: CrateReward;
}

const CrateAnimation: React.FC<CrateAnimationProps> = ({ possibleRewards, wonReward }) => {

    const insertWinningAward = () => {
        let rewards = [...possibleRewards];
        rewards.splice(45, 0, wonReward);
        return rewards;
    };
    const scrollingContentRef = useRef(null); // Add this useRef
    const getRarityColor = (rarity: number): string => {
        switch (rarity) {
            case 1: return styles.common;
            case 2: return styles.uncommon;
            case 3: return styles.rare;
            case 4: return styles.legendary;
            default: return '';
        }
    };
    
    useEffect(() => {
        const tolerance = 10;
        let lastPlayedIndex = -1;
        let rafId: number; // To store the ID returned by requestAnimationFrame

        const checkPositionAndPlaySound = () => {
            // Check if the ref is defined and the current property is not null
            if (!scrollingContentRef.current) {
                rafId = requestAnimationFrame(checkPositionAndPlaySound);
                return;
            }

            const computedStyle = getComputedStyle(scrollingContentRef.current);
            const transformValue = computedStyle.transform;
            const translateX = parseFloat(transformValue.split(',')[4].trim());
            const currentCount = Math.floor((Math.abs(translateX) + tolerance) / 200);

            if (currentCount > lastPlayedIndex) {
                new Audio('/click.mp3').play();
                lastPlayedIndex = currentCount;
            }

            // Schedule the next call and store the ID
            rafId = requestAnimationFrame(checkPositionAndPlaySound);
        };

        // Start the loop
        checkPositionAndPlaySound();

        // Cleanup function to stop the rAF loop
        return () => cancelAnimationFrame(rafId); // Use rafId here
    }, []);







    return (
        <>
            <style>
                {`
                    @keyframes scroll {
                        0% {
                            transform: translateX(400px);
                        }
                        100% {
                            transform: translateX(${-8000 - Math.floor(Math.random() * 180) }px);
                        }
                    }
                `}
            </style>

            <div className={styles.animationContainer}>
                {/* Applying the animation inline */}
                <div ref={scrollingContentRef} className={styles.scrollingContent} style={{ animation: "scroll 11s cubic-bezier(0.35, 0.1, 0.10, 1) forwards" }}>

                    {insertWinningAward().map((reward, index) => (
                        <div key={reward.rewardid} className={`${styles.rewardCard} ${styles.rewardBigCard} ${getRarityColor(reward.rarity)}`}>
                            {reward.reward.imageUrl && <img src={`${process.env.PUBLIC_URL}/gamba/${reward.reward.imageUrl}`} alt={reward.reward.name} />}

                            {reward.quantity > 1 && <span className={styles.quantity}>{reward.quantity}</span>}
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default CrateAnimation;
