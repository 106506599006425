import { APIResponse, APIUtility } from "../../ApiUtility";
import { JobDataDTO, PlaytimeSummary } from "../../SunsetAPI";
import {
  AccountCharDTO,
  AccountDTO,
  AccountPunishment,
  AccountSessionDTO,
  BugReport,
  CharacterDTO,
  DealershipVehicle,
  FactionCharacter,
  ItemDTO,
  Notes,
  PanelUserBio,
  PlayerNameHistory,
  PropertyDTO,
  Punishment,
  VehicleDTO,
  Wealth,
} from "./AccountInterface";

export class SunsetAPIAccount {
  private apiUtility: APIUtility;
  public baseURL: string;

  // constructor(baseURL: string = "http://localhost:7203/") {
    constructor(baseURL: string = "https://ss-rp.com/") {
    this.apiUtility = new APIUtility(baseURL);
    this.baseURL = baseURL;
  }

  async banCurrentUser(
    id: number,
    reason: string
  ): Promise<APIResponse<AccountDTO>> {
    return await this.apiUtility.post(`api/Account/banPlayer/${id}`, reason);
  }
  async unbanCurrentUser(id: number): Promise<APIResponse<AccountDTO>> {
    return await this.apiUtility.put(`api/Account/unbanPlayer/${id}`);
  }

  async getAccountByIdAndName(
    id: number,
    accountName: string
  ): Promise<APIResponse<AccountDTO>> {
    return await this.apiUtility.get(`api/account/get/${id}/${accountName}`);
  }
async GetBankTransactions(
  bankId: number
): Promise<APIResponse<AccountDTO>>{
  return await this.apiUtility.get(`api/account/bankHistory/${bankId}`);
}
  async IsAccountBanned(
    userid: number
  ): Promise<APIResponse<AccountPunishment[]>> {
    return await this.apiUtility.get(`api/Account/isBanned/${userid}`);
  }
  async GetAccountBansData(
    userId: number
  ): Promise<APIResponse<AccountPunishment[]>> {
    return await this.apiUtility.get(`api/account/BanInfo/${userId}`);
  }
  async getAccountPunishments(
    id: number
  ): Promise<APIResponse<AccountPunishment[]>> {
    return await this.apiUtility.get(`api/account/punishments/${id}`);
  }
  async expungePunishment(
    id: number,
    reason: string
  ): Promise<APIResponse<AccountPunishment[]>> {
    return await this.apiUtility.post(
      `api/account/expunge-punishment/${id}`,
      reason
    );
  }

  async getAccountsByPartialName(
    nameQuery: string
  ): Promise<APIResponse<AccountDTO[]>> {
    return await this.apiUtility.get(`api/account/byPartialName/${nameQuery}`);
  }
  async getAccountsByCharPartialName(
    nameQuery: string
  ): Promise<APIResponse<AccountCharDTO[]>> {
    return await this.apiUtility.get(
      `api/account/byCharPartialName/${nameQuery}`
    );
  }
  async setAdminLevel(
    id: number,
    level: number
  ): Promise<APIResponse<AccountDTO>> {
    return await this.apiUtility.post(`api/account/setAdminLevel/${id}/${level}`)
  }
  async setAccountName(
    id: number,
    name: string
  ): Promise<APIResponse<AccountDTO>> {
    return await this.apiUtility.post(`api/account/setAccountName/${id}/${name}`)
  }
  async setCash(
    id:number,
    cash: string
  ): Promise<APIResponse<AccountDTO>>{
    return await this.apiUtility.post(`api/character/setCash/${id}/${cash}`)
  }

  async setCharacterName(
    id:number,
    name: string
  ): Promise<APIResponse<AccountDTO>>{
    return await this.apiUtility.post(`api/character/setCharacterName/${id}/${name}`)
  }
 
  async getLastAccountSession(
    id: number,
    name: string
  ): Promise<APIResponse<AccountSessionDTO>> {
    return await this.apiUtility.get(`api/account/lastSession/${id}/${name}`);
  }

  async getUserBioByUsername(
    username: string
  ): Promise<APIResponse<PanelUserBio>> {
    return await this.apiUtility.get(`api/account/bio/${username}`);
  }
  async getPunishments(username: string): Promise<APIResponse<Punishment[]>> {
    return await this.apiUtility.get(`api/account/Punishments/${username}`);
  }
  async getBugs(): Promise<APIResponse<BugReport[]>> {
    return await this.apiUtility.get(`api/account/bugReports`);
  }

  async getCharactersByAccountIDAndNAme(
    id: number,
    name: string
  ): Promise<APIResponse<CharacterDTO[]>> {
    return await this.apiUtility.get(
      `api/character/byAccountIDAndName/${id}/${name}`
    );
  }
  async ResetCharacterSpawn(id: number): Promise<APIResponse<CharacterDTO>> {
    return await this.apiUtility.post(`api/character/resetspawn/${id}`);
  }

  async getCharacterJobData(
    id: number,
    name: string
  ): Promise<APIResponse<JobDataDTO>> {
    return await this.apiUtility.get(`api/character/jobData/${id}/${name}`);
  }
  async getPlaytimeSummary(
    id: number,
    name: string
  ): Promise<APIResponse<PlaytimeSummary[]>> {
    return await this.apiUtility.get(`api/character/playtimeSummary/${name}`);
  }
  async getNotes(username: string): Promise<APIResponse<Notes[]>> {
    return await this.apiUtility.get(`api/account/notes/${username}`);
  }
  async getPlayerNameHistory(
    id: number
  ): Promise<APIResponse<PlayerNameHistory[]>> {
    return await this.apiUtility.get(`api/Character/nameHistory/${id}`);
  }
  async getVehicles(charName: string): Promise<APIResponse<VehicleDTO[]>> {
    return await this.apiUtility.get(`api/assets/get-vehicles/${charName}`);
  }
  async getProperties(charName: string): Promise<APIResponse<PropertyDTO[]>> {
    return await this.apiUtility.get(`api/assets/get-properties/${charName}`);
  }
  async getWealth(charName: string): Promise<APIResponse<Wealth>> {
    return await this.apiUtility.get(`api/assets/get-wealth/${charName}`);
  }
  async getItems(charName: string): Promise<APIResponse<ItemDTO[]>> {
    return await this.apiUtility.get(`api/assets/get-items/${charName}`);
  }
  async editCurrentUserBio(bio: string): Promise<APIResponse<string>> {
    return await this.apiUtility.put("api/user/bio", { Bio: bio });
  }
  async getActivityByAccountId(
    accountName: string
  ): Promise<APIResponse<{ [key: number]: number }>> {
    return await this.apiUtility.get(
      `api/activity/by-account-name/${accountName}`
    );
  }
  async getUserFactions(): Promise<APIResponse<FactionCharacter[]>> {
    return await this.apiUtility.get("api/user/Factions");
  }
  async getDealershipVehicles(): Promise<APIResponse<DealershipVehicle[]>> {
    return await this.apiUtility.get("api/Assets/get-dealership-vehicles");
  }
}
