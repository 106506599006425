import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { AccountStats } from "./AccountStats";
import UserRoleBadge from "../../Components/UserRoleBadge";
import {
  AccountDTO,
  AccountPunishment,
  AccountSessionDTO,
} from "../../Networking/Interface/Account/AccountInterface";
import { InitialsAPI } from "../../Networking/InitialsAPI";
import { SunsetAPIAccountContext } from "../../Networking/Interface/Account/AccountAPIProvider";
import Banned from "../../ui/Banned";
import GetSessionButton from "../../Launcher/GetSessionButton";
import BanUser from "../../Staff/Actions/Ban";
import UnbanUser from "../../Staff/Actions/Unban";
import styled from "styled-components";
import ResetSpawnButton from "../../Staff/Actions/ResetSpawn";
import ForceUser from "../../Staff/Actions/ForceUser";

import SetAdminLevelButton from "../../Staff/Actions/SetAdmin";
import SetAccountNameButton from "../../Staff/Actions/AccountNameChange";
import { UserContext } from "../../User/UserContext";

interface AccountViewProps {
  accountDTO: AccountDTO;
}

interface OptionsMenuProps {
  show: boolean;
}

const OptionsMenu = styled.div<OptionsMenuProps>`
  position: absolute;
  top: 40px;
  right: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  opacity: ${(props) => (props.show ? "1" : "0")};
  transition: opacity 0.5s ease-in-out;
  z-index: 999;
`;

const OptionsIcon = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 1000;
`;

export const AccountView: React.FC<AccountViewProps> = ({ accountDTO }) => {
  const [modalKey, setModalKey] = useState<number>(0);
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [lastSession, setLastSession] = useState<AccountSessionDTO | null>(
    null
  );
  const [isBanned, setIsBanned] = useState<boolean | AccountPunishment>(false);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [isForced, setisForced] = useState<boolean>(false);
  const [openForceDialog, setOpenForceDialog] = useState<boolean>(false);
  const api = useContext(SunsetAPIAccountContext);
  const { user } = useContext(UserContext);
  const optionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (accountDTO.pfp) {
      setAvatarUrl(accountDTO.pfp);
    } else {
      const initialsApi = new InitialsAPI();
      setAvatarUrl(
        initialsApi.getAvatarURL({
          name: accountDTO.username.replace("_", "+"),
          size: 100,
        })
      );
    }
  }, [accountDTO]);

  const fetchAccountData = useCallback(async () => {
    try {
      const [sessionResponse, banResponse] = await Promise.all([
        api?.getLastAccountSession(accountDTO.id, accountDTO.username),
        api?.IsAccountBanned(accountDTO.id),
      ]);



      if (sessionResponse?.success) {
        setLastSession(sessionResponse.data || null); // Handle undefined data
      }

      if (banResponse?.success) {
        // console.log(banResponse)
        setIsBanned(
          Array.isArray(banResponse.data)
            ? banResponse.data.length > 0
              ? true
              : false
            : banResponse.data || false // Handle undefined data
        );
      }
    } catch (error) {
      console.error("Error fetching account data:", error);
    }
  }, [api, accountDTO]);

  useEffect(() => {
    fetchAccountData();
  }, [fetchAccountData]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleOptions = () => {
    setShowOptions((prev) => !prev);
  };

  const handleSuccess = useCallback(async () => {
    await fetchAccountData(); // Fetch latest data after any action
    setShowOptions(false);
  }, [fetchAccountData]);

  const handleBanSuccess = useCallback(async () => {
    await handleSuccess();
  }, [handleSuccess]);

  const handleForcePlayerSuccess = useCallback(async () => {
    setisForced(true);
    setOpenForceDialog(true);
    await handleSuccess();
  }, [handleSuccess]);

  const handleUnbanSuccess = useCallback(async () => {
    await handleSuccess();
  }, [handleSuccess]);

  const handleSetAdminLevelSuccess = useCallback(async () => {
    await handleSuccess();
  }, [handleSuccess]);

  const toggleModalKey = () => {
    setModalKey((prevKey) => prevKey + 1);
  };

const hasRole = (minLevel: number) => {
  return user && user.role >= minLevel;
}

  return (
    <div
      className="u-container card account-container"
      style={{ marginTop: 20, position: "relative" }}
    >
      {hasRole(3) && (
        <OptionsIcon onClick={toggleOptions}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 2.9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
          </svg>
        </OptionsIcon>
      )}
      <OptionsMenu ref={optionsRef} show={showOptions}>
        {!isBanned && hasRole(5) && (
          <BanUser accountDTO={accountDTO} onSuccess={handleBanSuccess} />
        )}

        {isBanned && hasRole(5) && (
          <UnbanUser accountDTO={accountDTO} onSuccess={handleUnbanSuccess} />
        )}
        {hasRole(3) && (
          <ResetSpawnButton
            key={modalKey}
            accountDTO={accountDTO}
            onModalClose={toggleModalKey}
          />
        )}
        {hasRole(6) && (
          <div
            style={{
              display: "flex",
              flexDirection: "column", // Adjust as needed for your layout (column or row)
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            
            <div> {/* Add spacing between buttons */}
              <ForceUser userId={accountDTO.id} onSuccess={handleForcePlayerSuccess} />
            </div>
            <div> {/* Add spacing between buttons */}
              <SetAccountNameButton userId={accountDTO.id} />
            </div>
            <div> {/* Add spacing between buttons */}
              <SetAdminLevelButton
                userId={accountDTO.id}
                onSuccess={handleSetAdminLevelSuccess}
              />
            </div>
            <div> {/* Add spacing between buttons */}
              <GetSessionButton accountDTO={accountDTO} />
            </div>
          </div>
        )}
      </OptionsMenu>
      {isBanned && <Banned account={accountDTO.username} />}
      <h1 className="header-large">{accountDTO.username}</h1>

      <div className="u-center">
        <UserRoleBadge role={accountDTO.adminLevel}></UserRoleBadge>
      </div>

      <div className="avatar-container">
        <img
          className="avatar-image"
          src={avatarUrl}
          alt={`Avatar for ${accountDTO.username}`}
        />
        {lastSession && lastSession.isOnline ? (
          <div className="online-indicator"></div>
        ) : (
          <div className="offline-indicator"></div>
        )}
      </div>
      {/* <BioView account={accountDTO}></BioView> */}
      <AccountStats accountDTO={accountDTO} lastSession={lastSession!} />
    </div>
  );
};

export default AccountView;
